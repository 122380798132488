const colors = {
	primaryBlue: '#0EC0E2',
	darkBlue: '#1E7181',
	green: '#B7C930',
	yellow: '#FFD337',
	orange: '#FF9629',
	lightPink: '#F1728E',
	pink: '#EF5879',
	red: '#E15344',
	grey: '#757575',
	lightGrey: '#EEE',
	white: '#FFFFFF',
	black: '#1A1A1A',
}

export default colors
