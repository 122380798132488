const initialState = {
	fetchState: 'readyToFetch',
}
const search = (state = initialState, action) => {
	switch (action.type) {
		case 'requestSearch': {
			return {
				...state,
				fetchState: 'isFetching',
			}
		}
		case 'receiveSearch': {
			let result = {}
			const {resultTypes} = action.body
			resultTypes.forEach(type => {
				result[type] = action.body[type]
			})
			return Object.assign({}, state, {
				fetchState: 'fetchComplete',
				resultTypes,
				result,
			})
		}
		default:
			return state
	}
}
export default search
