import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {useLocation, useHistory, useRouteMatch} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {setDimension} from '../../helpers/sendAnalyticsEvent'

import {
	fetchPublicDetails,
	fetchExperienceType,
	fetchGoogleAccessToken,
	fetchAccessToken,
} from '../actions/actionCreators'

import ReactTooltip from 'react-tooltip'
import PMMenu from './PMMenu'
import TopBar from './core/TopBar'
import ErrorBoundary from './ErrorBoundary'
import {includes} from 'lodash'

import {BodyContainer} from 'material-ui-responsive-drawer'

function Main({children, match}) {
	const [sideMenu, setSideMenu] = useState(true)
	const location = useLocation()
	const history = useHistory()
	const accounts = window.crossroadsAccounts
	const appbar = useSelector(state => state.appbar)
	const accessToken = useSelector(state => state.accessToken.accessToken)
	const accessTokenStatus = useSelector(state => state.accessToken.fetchState)
	const user = useSelector(state => state.user)
	const menu = useSelector(state => state.menu)
	const dispatch = useDispatch()
	useEffect(
		() => {
			let redirectURI = location.pathname + location.search
			if (accounts?.length === 0) {
				if (location.pathname === '/login') {
					history.replace(redirectURI)
				} else if (
					location.search === '?force' &&
					location.pathname !== '/login'
				) {
					history.replace('/login?force')
				} else if (redirectURI === '/') {
					history.replace('/login')
				} else {
					history.replace(
						`/login?redirect=${encodeURIComponent(redirectURI)}`
					)
				}
			}
		},
		[accounts, history, location.pathname, location.search]
	)
	useEffect(
		() => {
			document.title = appbar.documentTitle
		},
		[appbar.documentTitle]
	)
	useEffect(
		() => {
			if (accessTokenStatus === 'readyToFetch')
				dispatch(fetchAccessToken())
			if (accessTokenStatus === 'fetchComplete' && !accessToken)
				return window.location.reload()
		},
		[accessToken, accessTokenStatus, fetchAccessToken]
	)
	useEffect(
		() => {
			if (accessToken && user.experienceFetchState === 'readyToFetch') {
				dispatch(fetchPublicDetails())
				dispatch(fetchExperienceType('firstSteps'))
				dispatch(fetchGoogleAccessToken())
			}
		},
		[accessToken, user.experienceFetchState]
	)
	useEffect(
		() => {
			if (user.experienceDetails?.experience === 'Fellow') {
				setSideMenu(false)
			}
		},
		[user.experienceDetails]
	)
	useEffect(
		() => {
			if (user.error) {
				setSideMenu(false)
			}
		},
		[user.error]
	)
	useEffect(() => {
		ReactTooltip.rebuild()
	})
	useEffect(
		() => {
			if (user.fetchState === 'fetchComplete') {
				let {
					title,
					role,
					location: operationalCity,
				} = user.publicDetails
				if (role === 'fellow') {
					setDimension(role, operationalCity)
				} else {
					setDimension(title, operationalCity)
				}
			}
		},
		[user.fetchState, user.publicDetails]
	)
	let width
	// TODO: Fix repeating code in SecondaryMenu.js#73
	const showMenuFor = ['/', '/manageFellows', '/analysis/:title']
	if (
		!includes(showMenuFor, match.path) ||
		menu.hideSecondaryMenu ||
		sideMenu === false
	)
		width = 56
	else width = 300
	let loginPageRouteMatch = useRouteMatch({
		path: '/login',
	})
	if (loginPageRouteMatch) {
		return children
	}
	return (
		<div>
			<div>
				{accessToken !== null && (
					<div>
						<ErrorBoundary>
							<TopBar width={width} title={appbar.title} />
						</ErrorBoundary>
						<ErrorBoundary>
							<PMMenu width={width} />
						</ErrorBoundary>
					</div>
				)}
				<BodyContainer width={width}>
					<ErrorBoundary>{children}</ErrorBoundary>
				</BodyContainer>
				<ReactTooltip
					id="menu"
					effect="solid"
					className="menu-tooltip"
				/>
			</div>
		</div>
	)
}

Main.propTypes = {
	fetchAccessToken: PropTypes.func,
	accessTokenStatus: PropTypes.string,
	match: PropTypes.object,
	accessToken: PropTypes.string,
	conversations: PropTypes.object,
	appbar: PropTypes.shape({
		title: PropTypes.string,
		documentTitle: PropTypes.string,
	}),
	menu: PropTypes.object,
	children: PropTypes.object,
	user: PropTypes.object,
	fetchPublicDetails: PropTypes.func,
	fetchExperienceType: PropTypes.func,
	fetchGoogleAccessToken: PropTypes.func,
}

export default withRouter(Main)
