import crossroads from './SessionActions'
const config = require('../../../configs/config')
const errorConfig = config.errorLogging

export function requestRecordError() {
	return {
		type: 'requestRecordError',
	}
}

/**
 *
 * @param {number} errorCode The Error Code
 * @param {string} errorText The Title of the error
 * @param {object} errorData JSON error data
 */
export function fetchRecordError(errorCode, errorText, errorData) {
	return function(dispatch) {
		dispatch(requestRecordError())

		const name = errorConfig.applicationName

		if (!errorConfig.sendToSlack) {
			console.log(errorCode, errorText, errorData)
			return new Promise(resolve => resolve(true)).then(
				dispatch(receiveRecordError({}))
			)
		}
		return crossroads
			.post('/meta/errors', {
				body: {
					name,
					code: errorCode,
					err: errorText,
					data: errorData,
				},
			})
			.then(json => dispatch(receiveRecordError(json)))
			.catch(err => dispatch(receiveRecordError(err)))
	}
}

export function receiveRecordError(json) {
	return {
		type: 'receiveRecordError',
		json,
	}
}
