const blankState = {
	members: [],
	subcircles: [],
	rootCircle: {},
	fetchState: ''
}
function circle(state = {
	members: [],
	subcircles: [],
	rootCircle: {},
	fetchState: 'readyToFetch',
	mySubcirclesFetchState: 'readyToFetch'
}, action) {
	switch(action.type) {

	case 'requestCircleMembers':
		return Object.assign({}, state, {
			fetchState: 'isFetching'
		})
	case 'receiveCircleMembers':
		return Object.assign({}, state, {
			members: action.members,
			fetchState: 'fetchComplete'
		})
	case 'setActiveFellow':
		return Object.assign({}, state, {
			activeFellow: action.email
		})

	case 'requestMySubcircles':
		return Object.assign({}, state, {
			mySubcirclesFetchState: 'isFetching'
		})
	case 'receiveMySubcircles':
		return Object.assign({}, state, {
			subcircles: action.subcircles,
			id: action.rootCircle.id,
			name: action.rootCircle.name,
			mySubcirclesFetchState: 'fetchComplete'
		})
	case 'requestSubcircleMembers': {
		const {id} = action
		const index = state.subcircles.findIndex(subcircle => subcircle.id === id)
		let subcircles = Array.from(state.subcircles)
		subcircles = [
			...subcircles.slice(0, index),
			Object.assign({}, subcircles[index], {
				fetchState: 'isFetching'
			}),
			...subcircles.slice(index+1)
		]
		return Object.assign({}, state, {
			subcircles
		})
	}
	case 'receiveSubcircleMembers': {
		const {id} = action
		const index = state.subcircles.findIndex(subcircle => subcircle.id === id)
		let subcircles = Array.from(state.subcircles)
		subcircles = [
			...subcircles.slice(0, index),
			Object.assign({}, subcircles[index], {
				fetchState: 'fetchComplete',
				members: action.members
			}),
			...subcircles.slice(index+1)
		]
		return Object.assign({}, state, {
			subcircles
		})
	}

	case 'requestCreateCircle':
		return Object.assign({}, state, {
			mySubcirclesFetchState: 'isFetching'
		})
	case 'receiveCreateCircle': {
		const subcircles = Array.from(state.subcircles)
		subcircles.push(action.circle)
		return Object.assign({}, state, {
			subcircles,
			mySubcirclesFetchState: 'fetchComplete'
		})
	}

	case 'requestLogout':
		return Object.assign({}, blankState)

	default:
		return state
	}
}

export default circle
