const blankState = {
	questionsSets: {},
	questionsSetIds: [],
	fetchState: '',
}

function questionsSets(
	state = {
		questionsSets: {},
		questionsSetIds: [],
		fetchState: 'readyToFetch',
	},
	action
) {
	switch (action.type) {
		case 'requestQuestionsSets':
			return Object.assign({}, state, {
				fetchState: 'isFetching',
			})

		case 'receiveQuestionsSets': {
			let activeQuestionsSetId
			if (action.questionsSetIds && action.questionsSetIds[0])
				activeQuestionsSetId = action.questionsSetIds[0]
			return Object.assign({}, state, {
				questionsSets: action.questionsSets,
				questionsSetIds: action.questionsSetIds,
				fetchState: 'fetchComplete',
				activeQuestionsSetId,
			})
		}
		case 'setActiveQuestionsSet':
			return Object.assign({}, state, {
				activeQuestionsSetId: action.id,
			})
		case 'requestCreateQuestionsSet': {
			const {id} = action
			const {questionsSets, questionsSetIds} = state
			if (questionsSetIds.indexOf(id) !== -1) {
				questionsSets[id].fetchState = 'isFetching'
			}
			return Object.assign({}, state, {
				questionsSets,
			})
		}
		case 'receiveCreateQuestionsSet': {
			const {questionsSet} = action
			let questionsSets = Object.assign({}, state.questionsSets)
			questionsSets[questionsSet.id] = Object.assign(questionsSet, {
				fetchState: 'fetchComplete',
			})
			let questionsSetIds = state.questionsSetIds.slice(0)
			if (questionsSetIds.indexOf(questionsSet.id) === -1)
				questionsSetIds.unshift(questionsSet.id)
			return Object.assign({}, state, {
				questionsSets,
				questionsSetIds,
				activeQuestionsSetId: questionsSet.id,
			})
		}
		case 'requestQuestionsSet': {
			return state
		}
		case 'receiveQuestionsSet': {
			let questionsSetIds = [...state.questionsSetIds],
				questionsSets = {
					...state.questionsSets,
					[action.questionsSet.id]: action.questionsSet,
				}
			if (!questionsSetIds.includes(action.questionsSet.id)) {
				questionsSetIds.push(action.questionsSet.id)
			}
			return {
				...state,
				questionsSets,
				questionsSetIds,
			}
		}

		case 'requestLogout':
			return Object.assign({}, blankState)

		default:
			return state
	}
}

export default questionsSets
