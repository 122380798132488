import React from 'react'
import PropTypes from 'prop-types'

import {withRouter} from 'react-router'
import {Route, Link, NavLink} from 'react-router-dom'
import queryString from 'query-string'

import {CardTitle, CardHeader} from 'material-ui/Card'
import Divider from 'material-ui/Divider'
import {ListItem} from 'material-ui/List'
import Avatar from 'material-ui/Avatar'
import styled from 'styled-components'
import Loading from './Loading'
import {Button} from '@material-ui/core'
import config from '../../../configs/config'

const MenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	overflow: hidden;
`

const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex-grow: 1;
`

class SecondaryMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selected: 0,
			isExpanded: false,
		}
		this.renderAnalysisList = this.renderAnalysisList.bind(this)
		this.renderFellowsList = this.renderFellowsList.bind(this)
		this.renderConversationsList = this.renderConversationsList.bind(this)
		this.renderReviewList = this.renderReviewList.bind(this)
		this.showMore = this.showMore.bind(this)
	}

	setActiveFellow(email) {
		this.props.setActiveFellow(email)
		this.props.selectMenuItem('fellow')
		this.props.setActiveConversation(
			this.props.conversations.conversationIds[0]
		)
		this.props.toggleDrawerOpen()
	}

	ensureActiveFellow() {
		const {location, userType, users, fetchUser} = this.props
		const fellowEmail = queryString.parse(location.search).fellow
		if (
			userType === 'Program Manager' &&
			/^\/conversation/.test(location.pathname) &&
			!/[a-z]+[.a-z]+[0-9]{4}@teachforindia\.org/.test(fellowEmail)
		) {
			throw new Error(`${fellowEmail} is not a valid fellow email id`)
		}
		if (fellowEmail && !users[fellowEmail]) {
			fetchUser(fellowEmail)
		}
	}

	componentDidMount() {
		if (
			this.props.userType === 'other' ||
			this.props.userType === 'cityUser'
		)
			this.props.selectMenuItem('city')
		this.ensureConversations(this.props)
		this.ensureActiveFellow()
	}

	componentDidUpdate(prevProps) {
		this.ensureConversations(this.props)
		const {fetchConversations, circle, conversations} = this.props
		const oldActiveFellow = prevProps.circle.activeFellow
		const {activeFellow} = circle
		if (
			activeFellow &&
			(activeFellow !== oldActiveFellow ||
				conversations.fetchState === 'readyToFetch')
		) {
			fetchConversations({fellowEmail: activeFellow})
		}
		this.ensureActiveFellow()
	}

	ensureConversations(props) {
		const {
			conversations,
			userType,
			fetchFellowAnalytics,
			fetchReviewerConversationList,
			analysis,
			user,
			circle,
		} = props

		let query
		if (!userType) return
		if (userType === 'National') query = 'all'
		else if (userType === 'City') query = 'city'
		else query = 'self'

		const {activeFellow} = circle

		if (activeFellow && conversations.fetchState === 'readyToFetch')
			this.props.fetchConversations({fellowEmail: activeFellow})
		if (
			analysis.fetchState === 'readyToFetch' &&
			user.fetchState === 'fetchComplete'
		) {
			fetchFellowAnalytics({
				type: 'conversationsList',
				city: user.publicDetails.location,
				query,
			})
			fetchReviewerConversationList()
		}
	}

	renderHeader() {
		return (
			<div>
				<Route
					path="/analysis/:title"
					render={() => <CardTitle title="Conversations" />}
				/>
				<Route
					path="/review"
					render={() => <CardTitle title="Reviews" />}
				/>
				<Route
					path="/circles"
					render={() => <CardTitle title="My Circle" />}
				/>
				<Route
					path={['/conversation/:id', '/fellow/:fellowEmail']}
					render={() => {
						const {circle, users, userType} = this.props
						if (userType === 'Fellow') {
							return <CardTitle title="Conversations" />
						}
						const {activeFellow} = circle
						if (
							!users[activeFellow] ||
							users[activeFellow].fetchState !== 'fetchComplete'
						) {
							return <Loading />
						}
						const activeFellowDetails =
							users[activeFellow].publicDetails
						const {
							displayName,
							fellowTitle,
							pictureURL,
						} = activeFellowDetails
						return (
							<CardHeader
								titleStyle={{fontSize: '1.2em'}}
								title={displayName}
								subtitle={fellowTitle}
								avatar={pictureURL}
							/>
						)
					}}
				/>
			</div>
		)
	}

	renderAnalysisList() {
		const {conversations} = this.props.analysis
		const conversationDate = new Date(config.conversationDate).getTime()
		const filteredConversationItems = Object.values(conversations).filter(
			title => title.lastUpdateTime > conversationDate
		)
		let conversationHeadings = (!this.state.isExpanded
			? filteredConversationItems
			: Object.values(conversations)
		).map(title => title.title)
		const conversationsItem = conversationHeadings.map(
			(conversationHeading, index) => (
				<NavLink
					to={`/analysis/${conversationHeading}`}
					key={index}
					activeClassName="selected-list-item"
				>
					<ListItem
						onClick={() => {
							this.setState({selected: index})
							this.props.selectMenuItem('conversation')
							this.props.selectConversation(index)
							this.props.toggleDrawerOpen()
						}}
						primaryText={conversationHeading}
					/>
				</NavLink>
			)
		)
		return conversationsItem
	}

	renderFellowsList() {
		const {members} = this.props.circle
		const {users} = this.props

		const fellows = members
			.filter(member => member.association === 'member')
			.map(member => {
				const {email} = member
				const user = users[email]
				if (!user || user.fetchState !== 'fetchComplete')
					return (
						<div key={email}>
							<ListItem primaryText="Loading" />
						</div>
					)
				const {displayName, pictureURL} = user.publicDetails
				return (
					<Link to={`/fellow/${email}`} key={email}>
						<ListItem
							onClick={() => {
								this.setActiveFellow(email)
								this.props.selectMenuItem('fellow')
							}}
							primaryText={displayName}
							leftAvatar={<Avatar src={pictureURL} />}
						/>
					</Link>
				)
			})
		return fellows
	}

	renderConversationsList() {
		const {conversations, circle, userType} = this.props
		const {activeFellow} = circle
		const list = conversations.conversationIds || []
		const conversationItems = list.map(id => {
			const conversationTitle = conversations.conversations[id].title
			let to = `/conversation/${id}`
			if (userType === 'Program Manager') {
				to = `/conversation/${id}?fellow=${activeFellow}`
			}
			return (
				<NavLink to={to} key={id} activeClassName="selected-list-item">
					<ListItem
						primaryText={conversationTitle}
						onClick={() => {
							this.props.setActiveConversation(id)
							this.props.setAppbarTitle(conversationTitle)
							this.setState({selected: id})
						}}
					/>
				</NavLink>
			)
		})

		return conversationItems
	}

	renderReviewList() {
		const {reviewerList} = this.props
		if (reviewerList.fetchState !== 'fetchComplete') return null
		return Object.values(reviewerList.conversations).map(({title}) => {
			return (
				<NavLink
					to={`/review/${title}`}
					key={title}
					activeClassName="selected-list-item"
				>
					<ListItem
						primaryText={title}
						onClick={() => {
							this.props.setAppbarTitle(title)
							this.setState({selected: title})
						}}
					/>
				</NavLink>
			)
		})
	}

	renderList() {
		return (
			<div>
				<Route
					path={['/conversation/:id', '/fellow/:fellowId']}
					render={this.renderConversationsList}
				/>
				<Route
					path="/analysis/:title"
					render={this.renderAnalysisList}
				/>
				<Route path="/circles" render={this.renderFellowsList} />
				<Route path="/review" render={this.renderReviewList} />
			</div>
		)
	}

	showMore() {
		this.setState({isExpanded: !this.state.isExpanded})
	}

	render() {
		const {userType} = this.props
		if (userType === 'Fellow') {
			return null
		}
		return (
			<Route
				path={[
					'/analysis/:title',
					'/conversation/:id',
					'/circles',
					'/fellow/:fellowId',
					'/review',
				]}
				render={() => (
					<MenuContainer>
						{this.renderHeader()}
						<Divider />
						<ListContainer>{this.renderList()}</ListContainer>
						<Divider />
						<Route
							path={['/analysis/:title']}
							render={() => (
								<Button onClick={this.showMore}>
									{this.state.isExpanded
										? 'Hide older conversations'
										: 'Show older conversations'}
								</Button>
							)}
						/>
					</MenuContainer>
				)}
			/>
		)
	}
}

SecondaryMenu.propTypes = {
	userType: PropTypes.string.isRequired,
	accessToken: PropTypes.string,
	conversations: PropTypes.object,
	/** can possibly be removed */
	analysis: PropTypes.object,
	user: PropTypes.object,
	users: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
	menu: PropTypes.object,
	circle: PropTypes.object,
	/** material-ui-responsive-drawer */
	toggleDrawerOpen: PropTypes.func,
	setActiveFellow: PropTypes.func,
	selectMenuItem: PropTypes.func,
	setActiveConversation: PropTypes.func,
	fetchFellowAnalytics: PropTypes.func,
	fetchConversations: PropTypes.func,
	selectConversation: PropTypes.func,
	fetchUser: PropTypes.func,
	setAppbarTitle: PropTypes.func,
	reviewerList: PropTypes.object,
}

export default withRouter(SecondaryMenu)
