import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'

import {ResponsiveAppBar} from 'material-ui-responsive-drawer'

import MdArrowBack from 'react-icons/lib/md/arrow-back'

/** renders a TopBar element */
export default class TopBar extends React.Component {
	constructor(props) {
		super(props)
		this.renderTitle = this.renderTitle.bind(this)
	}

	renderTitle(title) {
		let titleElement
		titleElement = (
			<div className="topBar-title">
				<div className="back-arrow">
					<Link to="/">
						<MdArrowBack
							style={{cursor: 'pointer'}}
							size={30}
							color="white"
							onClick={this.props.toggleMenu}
						/>
					</Link>
				</div>
				<h1> {title} </h1>
			</div>
		)

		return titleElement
	}

	render() {
		let title

		if (this.props.title) title = this.props.title
		if (this.props.mainTitle && title) {
			title = `${this.props.mainTitle} - ${title}`
			title = this.renderTitle(title)
		}

		return (
			<ResponsiveAppBar
				id="topBar"
				width={this.props.width}
				title={title}
			/>
		)
	}
}

/**
 * TODO: fix iconElementLeft, onLeftIconButtonTouchTap, other extra props
 */
TopBar.propTypes = {
	/**
	 * Function called when back arrow is clicked
	 * May not be required
	 * #remove
	 */
	toggleMenu: PropTypes.func,

	/**
	 * The title to display
	 */
	title: PropTypes.string,

	/**
	 * Main title to display if showing a child page
	 */
	mainTitle: PropTypes.string,

	/**
	 * width of the menu shown
	 * should add this to redux
	 */
	width: PropTypes.number,
}
