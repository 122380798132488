const blankState = {
	fetchState: '',
}

function user(
	state = {
		fetchState: 'readyToFetch',
		experienceFetchState: 'readyToFetch',
	},
	action
) {
	switch (action.type) {
		case 'requestExperienceType':
			return Object.assign({}, state, {
				experienceFetchState: 'isFetching',
			})
		case 'receiveExperienceType':
			return Object.assign({}, state, {
				experienceFetchState: 'fetchComplete',
				experienceDetails: {
					experience: action.experience,
					location: action.location,
					analysis: action.analysis,
				},
			})
		case 'errorReceiveExperienceType':
			return Object.assign({}, state, {
				error: action,
			})
		case 'requestPublicDetails':
			return Object.assign({}, state, {
				fetchState: 'isFetching',
			})
		case 'receivePublicDetails':
			return Object.assign({}, state, {
				fetchState: 'fetchComplete',
				publicDetails: action.publicDetails,
				profiles: action.profiles,
			})
		case 'requestGoogleAccessToken':
			return Object.assign({}, state, {
				googleAccessTokenFetchState: 'isFetching',
			})
		case 'receiveGoogleAccessToken':
			return Object.assign({}, state, {
				googleAccessTokenFetchState: 'fetchComplete',
				googleAccessToken: action.googleAccessToken,
			})
		case 'requestLogout':
			return Object.assign({}, blankState)
		default:
			return state
	}
}

export default user
