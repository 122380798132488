import crossroads from './SessionActions'

export function requestSchema() {
	return {
		type: 'requestSchema',
	}
}
/**
 * fetches the schema of the form fields for classroom details
 *
 * @export
 * @param {string} [entity=Classroom] the entity to fetch schema for
 * @returns redux actions for fetching schema
 */
export function fetchSchema(entity) {
	if (!entity) entity = 'Classroom'
	return function(dispatch) {
		dispatch(requestSchema())

		return crossroads
			.get('/meta/schema', {params: {entity}})
			.then(json => dispatch(receiveSchema(json)))
			.catch(err => dispatch(receiveSchema(err)))
	}
}
export function receiveSchema(json) {
	if (json.errors === true || json.errors === 'yes' || !json.fields)
		return {
			type: 'errorReceiveSchema',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveSchema',
		fields: json.fields,
	}
}

export function requestClassroomData() {
	return {
		type: 'requestClassroomData',
	}
}
/**
 * Fetch the classroom data for the signed in user
 *
 * @export
 * @returns redux actions for fetching classroom data
 */
export function fetchClassroomData() {
	return function(dispatch) {
		dispatch(requestClassroomData())

		return crossroads
			.post('/classrooms', {})
			.then(json => dispatch(receiveClassroomData(json)))
			.catch(err => dispatch(receiveClassroomData(err)))
	}
}
export function receiveClassroomData(json) {
	if (json.errors === true || json.errors === 'yes' || !json.classrooms)
		return {
			type: 'errorReceiveClassroomData',
			errors: json.errors,
			errorMessage: json.errorMessage,
			json,
		}

	return {
		type: 'receiveClassroomData',
		classrooms: json.classrooms,
	}
}

export function requestSingleClassroomData(classCode) {
	return {
		type: 'requestSingleClassroomData',
		classCode,
	}
}
/**
 * Fetch the classroom data for the signed in user
 *
 * @export
 * @returns redux actions for fetching classroom data
 */
export function fetchSingleClassroomData(params) {
	return function(dispatch) {
		dispatch(requestSingleClassroomData(params.classCode))

		return crossroads
			.get('/classrooms', {params})
			.then(json => dispatch(receiveSingleClassroomData(json)))
			.catch(err => dispatch(receiveSingleClassroomData(err)))
	}
}
export function receiveSingleClassroomData(json) {
	if (json.errors === true || json.errors === 'yes' || !json.classroom)
		return {
			type: 'errorReceiveSingleClassroomData',
			errors: json.errors,
			errorMessage: json.errorMessage,
			json,
		}

	return {
		type: 'receiveSingleClassroomData',
		classroom: json.classroom,
	}
}

export function requestUpdateClassroomData(classCode) {
	return {
		type: 'requestUpdateClassroomData',
		classCode,
	}
}

export function updateClassroomData(body) {
	return function(dispatch) {
		dispatch(requestUpdateClassroomData(body.classCode))
		return crossroads
			.post('/classrooms/'+body.classCode, {body})
			.then(json => dispatch(receiveSingleClassroomData(json)))
			.catch(err => dispatch(receiveSingleClassroomData(err)))
	}
}
