/**
 * Records a section expansion analytics event
 * @param {string} label The label for the expand section event
 */
export function recordSectionExpansion(label) {
	window.gtag?.('event', 'expand_section', {
		event_category: 'engagement',
		event_label: label,
	})
}

/**
 *
 * @param {string} role The user role to record
 * @param {string} city The user's operational city
 */
export function setDimension(role, city) {
	window.gtag?.('event', 'set_dimension', {
		'tfi-role': role,
		'operational-city': city,
	})
}
