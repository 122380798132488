function conversationSettings(
	state = {
		threshold: 75,
		responsesKey: 'manager',
		cities: null,
		cohorts: null,
		compare: null,
	},
	action
) {
	switch (action.type) {
		case 'setThreshold': {
			let threshold = action.threshold
			if (threshold > 100) threshold = 100
			if (threshold < 0) threshold = 0
			return Object.assign({}, state, {threshold})
		}
		case 'setResponsesKey': {
			return Object.assign({}, state, {
				responsesKey: action.responsesKey,
			})
		}
		case 'setCities': {
			return Object.assign({}, state, {
				cities: action.cities,
			})
		}
		case 'setCohorts': {
			return Object.assign({}, state, {
				cohorts: action.cohorts,
			})
		}
		case 'setCompare': {
			return Object.assign({}, state, {
				compare: action.key,
			})
		}
		default:
			return state
	}
}
export default conversationSettings
