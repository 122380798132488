import crossroads from './SessionActions'

export function requestConversations() {
	return {
		type: 'requestConversations',
	}
}
export function fetchConversations(params) {
	return function(dispatch) {
		dispatch(requestConversations())

		return crossroads
			.get('/user/conversations', {params})
			.then(json => dispatch(receiveConversations(json, dispatch)))
			.catch(err => dispatch(receiveConversations(err)))
	}
}
export function receiveConversations(json, dispatch) {
	if (json.errors === true || json.errors === 'yes' || !json.conversations)
		return {
			type: 'errorReceiveConversations',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	const {conversations} = json
	let conversationIds = []
	const conversationsObject = {}
	conversations.map(conversation => {
		conversation.fetchState = 'readyToFetch'
		const {id} = conversation
		conversationIds.push(id)
		conversationsObject[id] = conversation
	})

	// if(conversationIds.length > 0)
	// 	dispatch(push('/conversation/'+conversationIds[0]))

	return {
		type: 'receiveConversations',
		conversations: conversationsObject,
		conversationIds,
	}
}

export function requestAddConversation() {
	return {
		type: 'requestAddConversation',
	}
}
export function fetchAddConversation(body) {
	return function(dispatch) {
		dispatch(requestAddConversation())

		return crossroads
			.post('/user/conversations', {body})
			.then(json => dispatch(receiveAddConversation(json)))
			.catch(err => dispatch(receiveAddConversation(err)))
	}
}
export function receiveAddConversation(json) {
	if (json.errors === true || json.errors === 'yes' || !json.conversation)
		return {
			type: 'errorAddConversation',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveAddConversation',
		conversation: json.conversation,
	}
}

export function requestEditConversation(id) {
	return {
		type: 'requestEditConversation',
		id,
	}
}

export function fetchEditConversation(body) {
	return function(dispatch) {
		dispatch(requestEditConversation(body.id))

		return crossroads
			.post('/user/conversations', {body})
			.then(json => dispatch(receiveEditConversation(json)))
			.catch(err => dispatch(receiveEditConversation(err)))
	}
}
export function receiveEditConversation(json) {
	if (json.errors === true || json.errors === 'yes' || !json.conversation)
		return {
			type: 'errorEditConversation',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveEditConversation',
		conversation: json.conversation,
	}
}

export function setActiveConversation(id) {
	return {
		type: 'setActiveConversation',
		id,
	}
}
export function requestConversation(id) {
	return {
		type: 'requestConversation',
		id,
	}
}
export function fetchConversation(params) {
	return function(dispatch) {
		dispatch(requestConversation(params.id))

		return crossroads
			.get('/user/conversations', {params})
			.then(json => dispatch(receiveConversation(json)))
			.catch(err => dispatch(receiveConversation(err)))
	}
}
export function receiveConversation(json) {
	if (json.errors === true || json.errors === 'yes' || !json.conversation)
		return {
			type: 'errorConversation',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveConversation',
		conversation: json.conversation,
	}
}

export function requestAddReflection() {
	return {
		type: 'requestAddReflection',
	}
}
export function fetchAddReflection(body) {
	return function(dispatch) {
		dispatch(requestAddReflection())

		return crossroads
			.post('/user/reflections', {body})
			.then(json => dispatch(receiveAddReflection(json)))
			.catch(err => dispatch(receiveAddReflection(err)))
	}
}
export function receiveAddReflection(json) {
	if (json.errors === true || json.errors === 'yes')
		return {
			type: 'errorAddReflection',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveAddReflection',
		reflections: json.reflections,
	}
}

export function requestEditScalesScores(conversationId) {
	return {
		type: 'requestEditScalesScores',
		message: 'Saving Scores...',
		conversationId,
	}
}
export function fetchEditScalesScores(body) {
	return function(dispatch) {
		dispatch(requestEditScalesScores(body.conversationId))

		return crossroads
			.post('/user/scalesScores', {body})
			.then(json => dispatch(receiveEditScalesScores(json)))
			.catch(err => dispatch(receiveEditScalesScores(err)))
	}
}
export function receiveEditScalesScores(json) {
	if (
		json.errors === true ||
		json.errors === 'yes' ||
		!json.scalesScoresDocument
	)
		return {
			type: 'errorEditScalesScores',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveEditScalesScores',
		message: 'Saved!',
		scalesScoresDocument: json.scalesScoresDocument,
	}
}

export function requestUpdateAnswersSet(id) {
	return {
		type: 'requestUpdateAnswersSet',
		id,
	}
}
export function fetchUpdateAnswersSet(body) {
	return function(dispatch) {
		dispatch(requestUpdateAnswersSet(body.conversationId))

		return crossroads
			.post('/user/feedbackAnswers', {body})
			.then(json => dispatch(receiveUpdateAnswersSet(json)))
			.catch(err => dispatch(receiveUpdateAnswersSet(err)))
	}
}
export function receiveUpdateAnswersSet(json) {
	if (json.errors === true || json.errors === 'yes' || !json.answersSet)
		return {
			type: 'errorUpdateAnswersSet',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveUpdateAnswersSet',
		answersSet: json.answersSet,
	}
}

export function requestAddArtefact(conversationId) {
	return {
		type: 'requestAddArtefact',
		conversationId,
	}
}
export function fetchAddArtefact(body) {
	return function(dispatch) {
		dispatch(requestAddArtefact(body.conversationId))

		return crossroads
			.post('/user/conversations/artefacts', {body})
			.then(json =>
				dispatch(
					receiveAddArtefact(
						_.merge(json, {conversationId: body.conversationId})
					)
				)
			)
			.catch(err => dispatch(receiveAddArtefact(err)))
	}
}
export function receiveAddArtefact(json) {
	if (json.errors === true || json.errors === 'yes' || !json.artefact)
		return {
			type: 'errorAddArtefact',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveAddArtefact',
		artefact: json.artefact,
		conversationId: json.conversationId,
	}
}

export function requestEditArtefact(id) {
	return {
		type: 'requestEditArtefact',
		id,
	}
}

export function fetchEditArtefact(body) {
	return function(dispatch) {
		dispatch(requestEditArtefact(body.id))

		return crossroads
			.post('/user/artefacts', {body})
			.then(json => dispatch(receiveEditArtefact(json)))
			.catch(err => dispatch(receiveEditArtefact(err)))
	}
}
export function receiveEditArtefact(json) {
	if (json.errors === true || json.errors === 'yes' || !json.artefact)
		return {
			type: 'errorEditArtefact',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveEditArtefact',
		artefact: json.artefact,
	}
}

export function requestConversationsByTitle() {
	return {
		type: 'requestConversationsByTitle',
	}
}
export function fetchConversationsByTitle(params) {
	return function(dispatch) {
		dispatch(requestConversationsByTitle())

		return crossroads
			.get('/user/conversations?circle=true', {params})
			.then(json =>
				dispatch(receiveConversationsByTitle(json, params.title))
			)
			.catch(err => dispatch(receiveConversationsByTitle(err)))
	}
}
export function receiveConversationsByTitle(json, activeTitle) {
	if (json.errors === true || json.errors === 'yes' || !json.conversations)
		return {
			type: 'errorReceiveConversationsByTitle',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	const {conversations} = json
	let fellows = []
	const conversationsObject = {}
	conversations.map(conversation => {
		conversation.fetchState = 'readyToFetch'
		if (
			conversation.constituentKeys.includes('scalesScoresDocumentManager')
		)
			conversation.singleScoresDocumentFetchState = 'readyToFetch'
		const {email} = conversation
		fellows.push(email)
		conversationsObject[email] = conversation
	})

	return {
		type: 'receiveConversationsByTitle',
		conversations: conversationsObject,
		fellows,
		activeTitle,
	}
}

export function requestSingleAnswersSet(fellowEmail) {
	return {
		type: 'requestSingleAnswersSet',
		fellowEmail,
	}
}
export function fetchSingleAnswersSet(params) {
	return function(dispatch) {
		dispatch(requestSingleAnswersSet(params.fellowEmail))

		return crossroads
			.get('/user/feedbackAnswers', {params})
			.then(json => dispatch(receiveSingleAnswersSet(json)))
			.catch(err => dispatch(receiveSingleAnswersSet(err)))
	}
}
export function receiveSingleAnswersSet(json) {
	if (json.errors === true || json.errors === 'yes' || !json.answersSet)
		return {
			type: 'errorSingleAnswersSet',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveSingleAnswersSet',
		message: 'Saved!',
		answersSet: json.answersSet,
	}
}

export function requestUpdateSingleAnswersSet(fellowEmail) {
	return {
		type: 'requestUpdateSingleAnswersSet',
		message: 'Saving Responses...',
		fellowEmail,
	}
}
export function fetchUpdateSingleAnswersSet(body) {
	return function(dispatch) {
		dispatch(requestUpdateSingleAnswersSet(body.fellowEmail))

		return crossroads
			.post('/user/feedbackAnswers', {body})
			.then(json => dispatch(receiveSingleAnswersSet(json)))
			.catch(err => dispatch(receiveSingleAnswersSet(err)))
	}
}

export function requestSingleScoresDocument(fellowEmail) {
	return {
		type: 'requestSingleScoresDocument',
		fellowEmail,
	}
}
export function fetchSingleScoresDocument(params) {
	return function(dispatch) {
		dispatch(requestSingleScoresDocument(params.fellowEmail))

		return crossroads
			.get('/user/scalesScores', {params})
			.then(json => dispatch(receiveSingleScoresDocument(json)))
			.catch(err => dispatch(receiveSingleScoresDocument(err)))
	}
}
export function receiveSingleScoresDocument(json) {
	if (
		json.errors === true ||
		json.errors === 'yes' ||
		!json.scalesScoresDocument
	)
		return {
			type: 'errorSingleScoresDocument',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveSingleScoresDocument',
		message: 'Saved!',
		scalesScoresDocument: json.scalesScoresDocument,
	}
}

export function requestUpdateSingleScoresDocument(fellowEmail) {
	return {
		type: 'requestUpdateSingleScoresDocument',
		message: 'Saving Responses...',
		fellowEmail,
	}
}
export function fetchUpdateSingleScoresDocument(body) {
	return function(dispatch) {
		dispatch(requestUpdateSingleScoresDocument(body.fellowEmail))

		return crossroads
			.post('/user/scalesScores', {body})
			.then(json => dispatch(receiveSingleScoresDocument(json)))
			.catch(err => dispatch(receiveSingleScoresDocument(err)))
	}
}

export function requestSingleConversationByTitle(title) {
	return {
		type: 'requestSingleConversationByTitle',
		title,
	}
}

export function fetchSingleConversationByTitle(title) {
	const params = {
		id: 'unknown',
		queryByTitle: true,
		title,
	}
	return function(dispatch) {
		dispatch(requestSingleConversationByTitle(title))
		return crossroads
			.get('/user/conversations', {params})
			.then(json => {
				dispatch(receiveConversation(json))
				dispatch(setActiveConversation(json.conversation.id))
			})
			.catch(err => dispatch(receiveConversation(err)))
	}
}

export function fetchReviewerConversationList() {
	return function(dispatch) {
		dispatch({type: 'requestReviewerConversationList'})

		const body = {
			type: 'conversationsList',
			query: 'reviewer',
		}

		return crossroads
			.post('/analytics/fellows', {body})
			.then(json =>
				dispatch({
					type: 'receiveReviewerConversationList',
					conversations: json,
				})
			)
			.catch(err =>
				dispatch({
					type: 'errorReceiveReviewerConversationList',
				})
			)
	}
}
