import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {ResponsiveDrawer, toggleDrawerOpen} from 'material-ui-responsive-drawer'
import ThinMenu from './ThinMenu'
import SecondaryMenu from './SecondaryMenu'
import Loading from './Loading'
import ErrorBoundary from './ErrorBoundary'
import {
	fetchConversations,
	fetchFellowAnalytics,
	fetchUser,
	setActiveFellow,
	selectMenuItem,
	setActiveConversation,
	selectConversation,
	setAppbarTitle,
	hideSecondaryMenu,
	fetchLogout,
	fetchReviewerConversationList,
} from '../actions/actionCreators'

class PMMenu extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		// this.ensureCircleMembers()
		this.ensureConversations()
	}

	componentDidUpdate() {
		// this.ensureCircleMembers()
		this.ensureConversations()
	}

	ensureCircleMembers() {
		const {accessToken, circle, user} = this.props
		if (
			accessToken &&
			user.publicDetails &&
			circle.fetchState === 'readyToFetch'
		)
			this.props.fetchCircleMembers({id: user.publicDetails.email})
		if (accessToken && circle.fetchState === 'fetchComplete')
			this.ensureFellowPublicDetails()
	}

	ensureConversations() {
		const {accessToken, conversations} = this.props
		if (accessToken && conversations.fetchState === 'readyToFetch') {
			this.props.fetchConversations()
		}
	}

	ensureFellowPublicDetails() {
		const {fetchUser, users} = this.props
		const {members} = this.props.circle
		members.map(member => {
			const {email} = member
			const user = users[email]
			if (!user || !user.fetchState || user.fetchState === 'readyToFetch')
				fetchUser(member.email)
		})
	}

	render() {
		const userType =
			this.props.user.experienceFetchState === 'fetchComplete'
				? this.props.user.experienceDetails.experience
				: null
		if (userType === null) {
			return (
				<ResponsiveDrawer
					containerClassName="drawerContainer"
					width={this.props.width}
				>
					<ThinMenu {...this.props} />
				</ResponsiveDrawer>
			)
		}
		return (
			<ResponsiveDrawer
				containerClassName="drawerContainer"
				width={this.props.width}
				containerStyle={{display: 'flex', overflowX: 'hidden'}}
			>
				<ThinMenu {...this.props} />
				<div style={{width: '1px', backgroundColor: '#EEEEEE'}} />
				<ErrorBoundary>
					<SecondaryMenu userType={userType} {...this.props} />
				</ErrorBoundary>
			</ResponsiveDrawer>
		)
	}
}

PMMenu.propTypes = {
	analysis: PropTypes.object.isRequired,
	accessToken: PropTypes.string,
	circle: PropTypes.object,
	conversations: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	users: PropTypes.object,
	menu: PropTypes.object.isRequired,
	fetchConversations: PropTypes.func.isRequired,
	fetchUser: PropTypes.func,
}

function mapStateToProps(state) {
	return {
		accessToken: state.accessToken.accessToken,
		user: state.user,
		users: state.users,
		menu: state.menu,
		circle: state.circle,
		conversations: state.conversations,
		selectedOption: state.menu.selectedOption,
		analysis: state.analysis,
		reviewerList: state.reviewerList,
		showReviewerMenu:
			state.reviewerList.fetchState === 'fetchComplete'
				? Object.keys(state.reviewerList.conversations).length > 0
				: false,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchConversations,
			fetchFellowAnalytics,
			setActiveFellow,
			selectMenuItem,
			setActiveConversation,
			selectConversation,
			setAppbarTitle,
			toggleDrawerOpen,
			hideSecondaryMenu,
			fetchLogout,
			fetchUser,
			fetchReviewerConversationList,
		},
		dispatch
	)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PMMenu)
