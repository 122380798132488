const blankState = {
	users: [],
	fetchState: ''
}

function users(state = {
	fetchState: 'readyToFetch',
	users: []
}, action) {
	switch(action.type) {
	case 'requestUser': {
		const {email} = action
		const newState = Object.assign({}, state, {
			users: Array.from(state.users)
		})
		if(!newState[email]) {
			newState[email] = {
				fetchState: 'isFetching'
			}
			newState.users.push(email)
		}
		return Object.assign({}, newState)
	}
	case 'receiveUser': {
		const {publicDetails} = action
		const newState = Object.assign({}, state)
		const {email} = publicDetails
		const user = state[email] || {}
		newState[email] = Object.assign({}, user, {publicDetails}, {fetchState: 'fetchComplete'})
		return Object.assign({}, newState)
	}
	case 'requestUsersBulk': {
		const {emails} = action
		const newState = Object.assign({}, state, {
			users: Array.from(state.users)
		})
		emails.map(email => {
			if(!newState[email]) {
				newState[email] = {
					fetchState: 'isFetching'
				}
				newState.users.push(email)
			}
		})
		return Object.assign({}, newState)
	}
	case 'receiveUsersBulk': {
		const {users} = action
		const newState = Object.assign({}, state)
		users.map(newUser => {
			const {email} = newUser
			const user = state[email] || {}
			newState[email] = Object.assign(
				{}, user, newUser, {fetchState: 'fetchComplete'}
			)
		})
		return Object.assign({}, newState)
	}
	case 'requestLogout':
		return Object.assign({}, blankState)
	default:
		return state
	}
}

export default users