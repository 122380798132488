import crossroads from './SessionActions'

export function requestCreateQuestionsSet(id) {
	return {
		type: 'requestCreateQuestionsSet',
		id
	}
}
export function fetchCreateQuestionsSet(body) {
	return function (dispatch) {

		dispatch(requestCreateQuestionsSet(body.id))

		return crossroads.post('/user/questionsSet', {body})
			.then(json => dispatch(receiveCreateQuestionsSet(json)))
			.catch(err => dispatch(receiveCreateQuestionsSet(err)))
	}
}
export function receiveCreateQuestionsSet(json) {

	if(json.errors === true || json.errors === 'yes' || !json.questionsSet)
		return {
			type: 'errorReceiveCreateQuestionsSet',
			errors: json.errors,
			errorMessage: json.errorMessage
		}

	return {
		type: 'receiveCreateQuestionsSet',
		questionsSet: json.questionsSet
	}
}

export function requestQuestionsSets() {
	return {
		type: 'requestQuestionsSets'
	}
}
export function fetchQuestionsSets() {
	return function (dispatch) {

		dispatch(requestQuestionsSets())

		return crossroads.get('/user/questionsSets')
			.then(json => dispatch(receiveQuestionsSets(json)))
			.catch(err => dispatch(receiveQuestionsSets(err)))
	}
}
export function receiveQuestionsSets(json) {

	if(json.errors === true || json.errors === 'yes' || !json.questionsSets)
		return {
			type: 'errorReceiveQuestionsSets',
			errors: json.errors,
			errorMessage: json.errorMessage
		}

	const {questionsSets} = json
	let questionsSetIds = []
	const questionsSetsObject = {}
	questionsSets.map(questionsSet => {
		questionsSet.fetchState = 'fetchComplete'
		const {id} = questionsSet
		questionsSetIds.push(id)
		questionsSetsObject[id] = questionsSet
	})

	return {
		type: 'receiveQuestionsSets',
		questionsSets: questionsSetsObject,
		questionsSetIds
	}
}
export function setActiveQuestionsSet(id) {
	return {
		type: 'setActiveQuestionsSet',
		id
	}
}

export function requestBulkCreateConversations() {
	return {
		type: 'requestBulkCreateConversations'
	}
}
export function fetchBulkCreateConversations(body) {
	return function (dispatch) {

		dispatch(requestBulkCreateConversations())

		return crossroads.post('/user/conversations/bulk', {body})
			.then(json => dispatch(receiveBulkCreateConversations(json)))
			.catch(err => dispatch(receiveBulkCreateConversations(err)))
	}
}
export function receiveBulkCreateConversations(json) {

	if(json.errors === true || json.errors === 'yes' || !json.queued)
		return {
			type: 'errorBulkCreateConversations',
			errors: json.errors,
			errorMessage: json.errorMessage
		}

	return {
		type: 'receiveBulkCreateConversations',
		message: `Queued up creation of ${json.queued} conversation(s)`
	}
}

