import React, {Suspense, lazy, useState, useEffect} from 'react'
import {render} from 'react-dom'

import {
	BrowserRouter,
	Route,
	Switch,
	useLocation,
	useHistory,
} from 'react-router-dom'
import {Provider} from 'react-redux'
import {MuiThemeProvider as ThemeProvider} from '@material-ui/core/styles'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import '@fontsource/open-sans'
import 'bootstrap/dist/css/bootstrap.min.css'
require('../less/app.less')
import {theme} from './Theme'

import Loading from './components/Loading'
import Main from './components/Main'
import store from './store'
import getCrossroadsAccountsEndpoints from '../helpers/crossroads-accounts-endpoints'
import config from '../../configs/config'

const CreateBulk = lazy(() => import('./components/create/CreateBulk'))
const Login = lazy(() => import('./components/Login/Login'))
const NoMatch = lazy(() => import('./components/NoMatch'))
const NewDashboard = lazy(() => import('./components/Dashboard/NewDashboard'))
const Resources = lazy(() => import('./components/Resources/Resources'))
const Dashboard = lazy(() => import('./components/Dashboard'))
const PMFDC = lazy(() => import('./components/PMFDC'))
const ClassroomHome = lazy(() =>
	import('./components/classrooms/ClassroomHome')
)
const ClassroomDetail = lazy(() =>
	import('./components/classrooms/ClassroomDetail')
)
const CircleManager = lazy(() =>
	import('./components/ManagementComponents/CircleManager')
)
const Reviews = lazy(() => import('./components/Reviews'))
const FellowDetail = lazy(() => import('./components/FellowDetail'))
const CreatorHome = lazy(() => import('./components/create/CreatorHome'))
const SurveyEditor = lazy(() => import('./components/create/SurveyEditor'))
const FellowManager = lazy(() =>
	import('./components/ManagementComponents/FellowManager')
)
const SharingRedirect = lazy(() => import('./components/SharingRedirect'))

const scopes = [
	'openid',
	'https://www.googleapis.com/auth/userinfo.email',
	'https://www.googleapis.com/auth/userinfo.profile',
	'https://www.googleapis.com/auth/drive',
]
const crossroadsAccountsObject = getCrossroadsAccountsEndpoints(
	config.applicationHost,
	config.crossroads,
	scopes
)
window.crossroadsAccountsObject = crossroadsAccountsObject
function App() {
	const [accounts, setAccounts] = useState(null)

	useEffect(() => {
		fetch(window.crossroadsAccountsObject.accountsEndpoints.get, {
			credentials: 'include',
		})
			.then(response => response.json())
			.then(json => {
				window.crossroadsAccounts = json.accounts
				setAccounts(json.accounts)
			})
	}, [])
	if (!accounts) return <div>Fetching accounts</div>
	else
		return (
			<div>
				<Provider store={store}>
					<BrowserRouter>
						<ThemeProvider theme={theme}>
							<MuiThemeProvider>
								<Main>
									<Suspense fallback={<Loading />}>
										<Switch>
											<Route
												exact
												path="/"
												component={Dashboard}
											/>
											<Route
												path="/login"
												component={Login}
											/>
											<Route
												path="/analysis/:title"
												component={Dashboard}
											/>
											<Route
												path="/circles"
												component={CircleManager}
											/>
											<Route
												path="/classrooms/:classID"
												component={ClassroomDetail}
											/>
											<Route
												path="/classrooms"
												component={ClassroomHome}
											/>
											<Route
												path="/fellow/:fellowID"
												component={Dashboard}
											/>
											<Route
												path="/conversation/:id"
												component={Dashboard}
											/>
											<Route
												path="/surveys"
												component={CreatorHome}
											/>
											<Route
												path="/survey/:questionsSetId"
												component={SurveyEditor}
											/>
											<Route
												path="/create"
												component={CreatorHome}
											/>
											<Route
												path="/s/:title"
												component={SharingRedirect}
											/>
											<Route
												path="/feedback/:title"
												component={PMFDC}
											/>
											<Route
												path="/manageFellows"
												component={FellowManager}
											/>
											<Route
												path="/dashboard"
												component={NewDashboard}
											/>
											<Route
												path="/resources"
												component={Resources}
											/>
											<Route
												path="/review/conversation/:id"
												component={Dashboard}
											/>
											<Route
												path="/review/:conversationTitle"
												component={Reviews}
											/>
											<Route
												path="/review"
												component={Reviews}
											/>
											<Route
												path="*"
												component={NoMatch}
											/>
										</Switch>
									</Suspense>
								</Main>
							</MuiThemeProvider>
						</ThemeProvider>
					</BrowserRouter>
				</Provider>
			</div>
		)
}

render(<App />, document.getElementById('root'))
