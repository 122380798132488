export * from './SessionActions'
export * from './UserActions'
export * from './ConversationsActions'
export * from './CreaterActions'
export * from './AnalyticsActions'
export * from './ClassroomActions'
export * from './QuizActions'
export * from './SearchActions'
export * from './ErrorActions'

export function clearMessage() {
	return {
		type: 'clearMessage',
	}
}

export function setActiveFellow(email) {
	return {
		type: 'setActiveFellow',
		email,
	}
}

// Set the content to render in a conversation
export function setViewableContent(content) {
	return {
		type: 'setViewableContent',
		content,
	}
}

export function setAppbarTitle(title, documentTitle) {
	if (!title) {
		title = 'Firststeps'
		documentTitle = 'Firststeps'
	}
	return {
		type: 'setAppbarTitle',
		title,
		documentTitle: documentTitle ? documentTitle : `${title} - Firststeps`,
	}
}

export function selectMenuItem(menuItem) {
	window.gtag?.('event', 'select_content', {content_type: menuItem})
	return {
		type: 'selectMenuItem',
		menuItem,
	}
}

export function selectConversation(conversation) {
	return {
		type: 'selectConversation',
		conversation,
	}
}

export function clearError() {
	return {
		type: 'clearError',
	}
}
