import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import MenuItem from 'material-ui/MenuItem'
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'

import ConversationIcon from 'material-ui/svg-icons/communication/forum'
import CircleIcon from 'material-ui/svg-icons/editor/bubble-chart'
import HelpIcon from 'material-ui/svg-icons/action/help'
import SignoutIcon from 'material-ui/svg-icons/action/exit-to-app'
import RateReviewIcon from '@material-ui/icons/RateReview'
import Avatar from 'material-ui/Avatar'

import '../../less/ThinMenu.less'

export default class ThinMenu extends React.Component {
	constructor(props) {
		super(props)
	}

	renderAvatar() {
		const {user} = this.props
		let avatar = (
			<Avatar size={24}>{user.publicDetails.firstName.charAt(0)}</Avatar>
		)
		let imageSrc = user.publicDetails.src || user.publicDetails.pictureURL
		if (imageSrc) avatar = <Avatar src={imageSrc} size={24} />
		return avatar
	}

	renderTopIcons() {
		const {showReviewerMenu} = this.props
		const userType =
			this.props.user?.experienceFetchState === 'fetchComplete'
				? this.props.user?.experienceDetails.experience
				: null
		let icons = []
		if (userType === 'Program Manager') {
			icons = [
				<Link to="/" key="conv">
					<MenuItem
						className="menu-item"
						data-tip="Survey Analysis"
						onClick={() => {
							this.props.selectMenuItem('conversation')
							this.props.hideSecondaryMenu(false)
						}}
						data-for="menu"
					>
						<ConversationIcon />
					</MenuItem>
				</Link>,
				<Link to="/circles" key="circles">
					<MenuItem
						className="menu-item"
						data-tip="My LC"
						onClick={() => {
							this.props.selectMenuItem('circle')
							this.props.hideSecondaryMenu(false)
						}}
						data-for="menu"
					>
						<CircleIcon />
					</MenuItem>
				</Link>,
				// <Link to="/classrooms" key="classrooms">
				// 	<MenuItem className="menu-item" data-tip="Classrooms">
				// 		<ClassIcon />
				// 	</MenuItem>
				// </Link>,
			]
			if (showReviewerMenu) {
				icons.push(
					<Link to="/review" key="reviews">
						<MenuItem
							className="menu-item"
							data-tip="Reviews"
							onClick={() => {
								this.props.selectMenuItem('circle')
								this.props.hideSecondaryMenu(false)
							}}
							data-for="menu"
						>
							<RateReviewIcon />
						</MenuItem>
					</Link>
				)
			}
		} else if (userType === 'Fellow') {
			icons = [
				<Link to="/" key="conv">
					<MenuItem
						className="menu-item"
						onClick={() => {
							this.props.selectMenuItem('fellowConversation')
							this.props.hideSecondaryMenu(false)
						}}
						data-tip="Conversations"
						data-for="menu"
					>
						<ConversationIcon />
					</MenuItem>
				</Link>,
			]
		} else if (userType === 'National' || userType === 'City') {
			icons = [
				<Link to="/" key="conv">
					<MenuItem
						className="menu-item"
						key="conv"
						onClick={() => {
							this.props.selectMenuItem('city')
							this.props.hideSecondaryMenu(false)
						}}
						data-tip="Analysis"
						data-for="menu"
					>
						<ConversationIcon />
					</MenuItem>
				</Link>,
			]
		}
		return <div className="icons-top">{icons}</div>
	}

	renderBottomIcons() {
		const {user, fetchLogout, accessToken} = this.props
		return (
			<div className="icons-bottom">
				<MenuItem
					className="menu-item"
					href="https://teachforindia.keka.com/ui/#/me/helpdesk/mytickets"
					target="_blank"
					data-tip="Get Help"
					data-for="menu"
				>
					<HelpIcon />
				</MenuItem>
				{user?.fetchState === 'fetchComplete' && (
					<IconMenu
						key="avatar"
						iconButtonElement={
							<IconButton>{this.renderAvatar()}</IconButton>
						}
						anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
						targetOrigin={{vertical: 'bottom', horizontal: 'left'}}
						style={{margin: '0 auto'}}
					>
						<MenuItem
							rightIcon={<SignoutIcon />}
							primaryText="Sign Out"
							onClick={() => {
								fetchLogout(accessToken)
							}}
						/>
						<a
							href="https://aboutme.google.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							<MenuItem
								primaryText={user.publicDetails.displayName}
							/>
						</a>
					</IconMenu>
				)}
			</div>
		)
	}

	render() {
		return (
			<div className="menu-container">
				{this.renderTopIcons()}
				{this.renderBottomIcons()}
			</div>
		)
	}
}

ThinMenu.propTypes = {
	/** redux user object */
	user: PropTypes.object,

	/** redux function to change the selected menu item */
	selectMenuItem: PropTypes.func,

	/** redux function to hide secondary menu */
	hideSecondaryMenu: PropTypes.func,

	/** redux function to logout */
	fetchLogout: PropTypes.func,

	accessToken: PropTypes.string,

	showReviewerMenu: PropTypes.bool,
}
