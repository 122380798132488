const blankState = {
	fetchState: '',
}

function conversations(
	state = {
		fetchState: 'readyToFetch',
		conversations: {},
		conversationIds: [],
		activeConversationId: null,
	},
	action
) {
	switch (action.type) {
		case 'requestConversations':
			return Object.assign({}, state, {
				fetchState: 'isFetching',
				isFetching: 'conversations',
			})
		case 'receiveConversations': {
			let activeConversationId
			if (action.conversationIds && action.conversationIds[0])
				activeConversationId = action.conversationIds[0]
			return Object.assign({}, state, {
				fetchState: 'fetchComplete',
				conversations: action.conversations,
				conversationIds: action.conversationIds,
				activeConversationId,
			})
		}

		case 'requestAddConversation':
			return Object.assign({}, state, {
				fetchState: 'isFetching',
				isFetching: 'addConversation',
			})
		case 'receiveAddConversation': {
			const {conversation} = action
			let conversations = Object.assign({}, state.conversations)
			conversations[conversation.id] = conversation
			let conversationIds = state.conversationIds.slice(0) || []
			conversationIds.unshift(conversation.id)
			return Object.assign({}, state, {
				conversations,
				conversationIds,
				activeConversationId: conversation.id,
			})
		}
		case 'errorAddConversation':
			return Object.assign({}, state, {
				errorMessage: action.errorMessage,
			})

		case 'setActiveConversation':
			return Object.assign({}, state, {
				activeConversationId: action.id,
			})

		case 'requestConversation': {
			const conversations = state.conversations
			const conversation = conversations[action.id]
			conversations[action.id] = Object.assign({}, conversation, {
				fetchState: 'isFetching',
			})
			return Object.assign({}, state, {
				conversations,
			})
		}
		case 'receiveConversation': {
			const {id} = action.conversation
			const conversation = state.conversations[id] || {}
			const conversations = Object.assign({}, state.conversations)
			conversations[id] = Object.assign(
				{},
				conversation,
				action.conversation,
				{
					fetchState: 'fetchComplete',
				}
			)
			return Object.assign({}, state, {conversations})
		}

		case 'errorConversation': {
			return Object.assign({}, state, {
				errorMessage: action.errorMessage,
			})
		}

		case 'clearError': {
			return Object.assign({}, state, {
				errorMessage: null,
			})
		}

		case 'requestEditConversation': {
			const conversations = state.conversations
			const conversation = conversations[action.id]
			conversations[action.id] = Object.assign({}, conversation, {
				fetchState: 'isFetching',
				previousFetchState: conversation.fetchState,
			})
			return Object.assign({}, state, {
				conversations,
			})
		}
		case 'receiveEditConversation': {
			const {id} = action.conversation
			const conversation = state.conversations[id] || {}
			const conversations = Object.assign({}, state.conversations)
			conversations[id] = Object.assign(
				{},
				conversation,
				action.conversation,
				{
					fetchState: conversation.previousFetchState,
				}
			)
			return Object.assign({}, state, {conversations})
		}

		case 'requestAddReflection': {
			return state
		}
		case 'receiveAddReflection': {
			const {reflections} = action
			const {conversationId} = reflections
			const newState = Object.assign({}, state)
			const conversation = newState.conversations[conversationId]
			const newConversation = Object.assign(conversation, {
				reflections,
			})
			newState.conversations[conversationId] = newConversation
			return newState
		}

		case 'requestEditScalesScores': {
			const {conversationId} = action
			const conversation = state.conversations[conversationId]
			conversation.fetchEditScalesScores = 'isFetching'
			return state
		}
		case 'receiveEditScalesScores': {
			const {scalesScoresDocument} = action
			const {conversationId} = scalesScoresDocument
			const newState = Object.assign({}, state)
			const conversation = newState.conversations[conversationId]
			let key
			if (scalesScoresDocument.type === 'self')
				key = 'scalesScoresDocumentSelf'
			if (scalesScoresDocument.type === 'manager')
				key = 'scalesScoresDocumentManager'
			conversation.fetchEditScalesScores = 'fetchComplete'
			const newConversation = Object.assign(conversation, {
				[key]: scalesScoresDocument,
			})
			newState.conversations[conversationId] = newConversation
			return newState
		}

		case 'requestUpdateAnswersSet': {
			const conversations = state.conversations
			const conversation = conversations[action.id]
			conversations[action.id] = Object.assign({}, conversation, {
				fetchState: 'isFetching',
			})
			return Object.assign({}, state, {
				conversations,
			})
		}
		case 'receiveUpdateAnswersSet': {
			const {answersSet} = action
			const {conversationId, type} = answersSet
			const conversations = state.conversations
			const conversation = conversations[conversationId]
			let key = 'answersSet'
			if (type === 'self') key += 'Self'
			if (type === 'manager') key += 'Manager'
			if (type === 'reviewer') key += 'Reviewer'
			conversations[conversationId] = Object.assign({}, conversation, {
				fetchState: 'fetchComplete',
				[key]: answersSet,
			})
			return Object.assign({}, state, {
				conversations,
			})
		}

		case 'requestAddArtefact': {
			const {conversations} = state
			const conversation = conversations[action.conversationId]
			conversations[action.conversationId] = Object.assign(
				{},
				conversation,
				{
					fetchState: 'isFetching',
				}
			)
			return Object.assign({}, state, {
				conversations,
			})
		}
		case 'receiveAddArtefact': {
			const {conversationId, artefact} = action
			const conversation = state.conversations[conversationId]
			const conversations = Object.assign({}, state.conversations)
			conversation.artefacts.unshift(artefact)
			conversations[conversationId] = Object.assign({}, conversation, {
				fetchState: 'fetchComplete',
			})
			return Object.assign({}, state, {conversations})
		}

		case 'receiveTestUpdate': {
			const {conversationId} = action
			const conversation = state.conversations[conversationId]
			const conversations = Object.assign({}, state.conversations)
			conversation.quiz.state = action.state
			conversation.quiz.startTime = action.startTime
			return Object.assign({}, state, {conversations})
		}

		case 'receiveTestRemainingTime': {
			const {conversationId, remainingTime} = action
			const conversation = state.conversations[conversationId]
			const conversations = Object.assign({}, state.conversations)
			conversation.quiz.state = action.state
			conversation.quiz.remainingTime = remainingTime
			conversations[conversationId] = Object.assign({}, conversation)
			return Object.assign({}, state, {conversations})
		}

		case 'requestLogout':
			return Object.assign({}, blankState)

		default:
			return state
	}
}

export default conversations
