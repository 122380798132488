import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from 'material-ui/CircularProgress'
/**
 * Renders a loading spinner
 */
function Loading({type}) {
	switch (type) {
		case 'large': {
			const diameter = Math.min(window.innerHeight, window.innerWidth)
			return (
				<div className="text-center">
					<CircularProgress
						size={diameter / 3}
						thickness={diameter / 60}
					/>
				</div>
			)
		}
		default: {
			return (
				<div style={{display: 'flex', justifyContent: 'space-around'}}>
					<CircularProgress />
				</div>
			)
		}
	}
}

Loading.propTypes = {
	/**
	 * Size of spinner
	 */
	type: PropTypes.oneOf(['large', 'default']),
}

export default Loading
