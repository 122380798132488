/* global crossroadsAccounts crossroadsAccountsObject*/
import config from '../../../configs/config'
const crossroadsConfig = config.crossroads

import Crossroads from 'crossroads-js'
const crossroads = new Crossroads({
	apiKey: crossroadsConfig.apiKey,
	host: crossroadsConfig.host,
	port: crossroadsConfig.port,
	tokenExpiryHandler: refreshAccessToken,
})

function refreshAccessToken(originalParams) {
	return fetch(crossroadsAccountsObject.accountsEndpoints.refresh, {
		credentials: 'include',
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({accessToken: crossroads.accessToken}),
	})
		.then(response => response.json())
		.then(json => {
			const account = json.accounts[0]
			crossroads.dispatch(receiveAccessToken(account))
			const {options} = originalParams
			options.headers.Authorization = `Basic ${new Buffer(
				crossroadsConfig.apiKey + ':' + crossroads.accessToken
			).toString('base64')}`
			return crossroads.call(options, originalParams.body)
		})
}

export default crossroads

export function requestAccessToken() {
	return {
		type: 'requestAccessToken',
	}
}
export function fetchAccessToken() {
	return function(dispatch) {
		crossroads.dispatch = dispatch

		dispatch(requestAccessToken())
		const account = crossroadsAccounts[0]

		return dispatch(receiveAccessToken(account))
	}
}
export function receiveAccessToken(json) {
	if (!json || json.errors === true || json.errors === 'yes')
		return {
			type: 'errorReceiveAccessToken',
			errors: json?.errors,
			errorMessage: json?.errorMessage,
		}

	crossroads.updateAccessToken(json.accessToken)

	return {
		type: 'receiveAccessToken',
		accessToken: json.accessToken,
		loginURI: json.loginURI,
	}
}

export function requestLogout() {
	return {
		type: 'requestLogout',
	}
}
export function fetchLogout() {
	return function(dispatch) {
		dispatch(requestLogout())

		return fetch(crossroadsAccountsObject.accountsEndpoints.destroy, {
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({accessToken: crossroads.accessToken}),
		})
			.then(response => response.json())
			.then(json => dispatch(receiveAccessToken(json)))
	}
}
