function menu(state={
  selectedOption: 'conversation',
  selectedConversation: 0
}, action){
  switch(action.type){
    case 'selectMenuItem':
      return Object.assign({}, state, {selectedOption:action.menuItem})
    case 'selectConversation':
      return Object.assign({}, state, {selectedConversation:action.conversation})
    case 'hideSecondaryMenu':
      return Object.assign(
        {},
        state,
        {hideSecondaryMenu: action.hideSecondaryMenu}
      )
    default:
      return state
  }
}

export default menu
