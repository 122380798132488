import crossroads from './SessionActions'
import {fetchRecordError} from './ErrorActions'

export function requestFellowAnalytics() {
	return {
		type: 'requestFellowAnalytics',
	}
}
export function fetchFellowAnalytics(body) {
	return function(dispatch) {
		dispatch(requestFellowAnalytics())

		return crossroads
			.post('/analytics/fellows', {body})
			.then(json => dispatch(receiveFellowAnalytics(json, body)))
			.catch(err => dispatch(receiveFellowAnalytics(err, body)))
	}
}
export function receiveFellowAnalytics(json, body) {
	if (json.errors === true || json.errors === 'yes' || !json.conversations)
		return function(dispatch) {
			dispatch(
				fetchRecordError(
					1001,
					`/analytics/fellows: ${json.errorMessage}`,
					{errors: json.errors, body}
				)
			)
			return {
				type: 'errorReceiveFellowAnalytics',
				errors: json.errors,
				errorMessage: json.errorMessage,
			}
		}

	const {conversations} = json
	let conversationTitles = []
	const conversationsObject = {}
	conversations.map(conversation => {
		conversation.fetchState = 'readyToFetch'
		conversation.completionFetchState = 'readyToFetch'
		const {title} = conversation
		conversationTitles.push(title)
		conversationsObject[title] = conversation
	})

	return {
		type: 'receiveFellowAnalytics',
		conversations: conversationsObject,
		conversationTitles,
	}
}

export function requestDetailFellowAnalytics(title) {
	return {
		type: 'requestDetailFellowAnalytics',
		title,
	}
}
export function requestDetailFellowAnalyticsCompletion(title) {
	return {
		type: 'requestDetailFellowAnalyticsCompletion',
		title,
	}
}
export function fetchDetailFellowAnalytics(body) {
	return function(dispatch) {
		if (body.type === 'conversationsCompletion')
			dispatch(requestDetailFellowAnalyticsCompletion(body.title))
		else dispatch(requestDetailFellowAnalytics(body.title))

		return crossroads
			.post('/analytics/fellows', {body})
			.then(json => dispatch(receiveDetailFellowAnalytics(json)))
			.catch(err => dispatch(receiveDetailFellowAnalytics(err)))
	}
}
export function receiveDetailFellowAnalytics(json) {
	if (
		json.errors === true ||
		json.errors === 'yes' ||
		(!json.distributionList && !json.conversation)
	)
		return {
			type: 'errorReceiveFellowAnalytics',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	if (json.conversation)
		return {
			type: 'receiveDetailFellowAnalyticsCompletion',
			conversation: json.conversation,
		}

	return {
		type: 'receiveDetailFellowAnalytics',
		distribution: json.distribution,
		distributionList: json.distributionList,
		title: json.title,
	}
}

export function requestFilteredFellowAnalytics(
	title,
	filters = {},
	groups = []
) {
	return {
		type: 'requestFilteredFellowAnalytics',
		title,
		filters,
		groups,
	}
}
export function requestFilteredFellowAnalyticsCompletion(
	title,
	filters = {},
	groups = []
) {
	return {
		type: 'requestFilteredFellowAnalyticsCompletion',
		title,
		filters,
		groups,
	}
}
export function fetchFilteredFellowAnalytics(body) {
	return function(dispatch) {
		if (body.type === 'conversationsCompletion') {
			dispatch(
				requestFilteredFellowAnalyticsCompletion(
					body.title,
					body.filters,
					body.groups
				)
			)
		} else {
			dispatch(
				requestFilteredFellowAnalytics(
					body.title,
					body.filters,
					body.groups
				)
			)
		}
		return crossroads
			.post('/analytics/fellows', {body})
			.then(json =>
				dispatch(
					receiveFilteredFellowAnalytics(
						json,
						body.filters,
						body.groups,
						body.type
					)
				)
			)
			.catch(err => dispatch(receiveFilteredFellowAnalytics(err)))
	}
}
export function receiveFilteredFellowAnalytics(
	json,
	filters = {},
	groups = [],
	type
) {
	if (json.errors === true || json.errors === 'yes' || !json.conversation)
		return {
			type: 'errorReceiveFilteredFellowAnalytics',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}
	if (type === 'conversationsCompletion') {
		return {
			type: 'receiveFilteredFellowAnalyticsCompletion',
			conversation: json.conversation,
			filters,
			groups,
		}
	} else {
		return {
			type: 'receiveFilteredFellowAnalytics',
			conversation: json.conversation,
			filters,
			groups,
		}
	}
}

export function requestAnalyticsRaw(body) {
	return {
		type: 'requestAnalyticsRaw',
		body,
	}
}

/**
 * Fetch and download raw data as csv
 * @param {object} body the request body
 * @param {string} body.title the conversation title
 * @param {string} body.type the type of responses - 'self' or 'manager'
 */
export function fetchAnalyticsRaw(body) {
	return function(dispatch) {
		dispatch(requestAnalyticsRaw(body))
		return crossroads
			.post('/analytics/raw', {body})
			.then(json => dispatch(receiveAnalyticsRaw(json)))
			.catch(err => dispatch(receiveAnalyticsRaw(err)))
	}
}

export function receiveAnalyticsRaw(json) {
	if (json.errors)
		return {
			type: 'errorReceiveAnalyticsRaw',
			message: json.errorMessage,
		}
	const {fileName} = json
	crossroads.files({fileName: encodeURIComponent(fileName)})
	return {
		type: 'receiveAnalyticsRaw',
		fileName,
	}
}

export function fetchQuestionsSet(id) {
	return function(dispatch) {
		dispatch({
			type: 'requestQuestionsSet',
			id,
		})

		return crossroads
			.get(`/user/questionsSet?id=${id}`)
			.then(json => dispatch(receiveQuestionsSet(json)))
			.catch(err => dispatch(receiveQuestionsSet(err)))
	}
}

export function receiveQuestionsSet(json) {
	if (!json.questionsSet)
		return {
			type: 'errorReceiveQuestionsSet',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}
	return {
		type: 'receiveQuestionsSet',
		questionsSet: json.questionsSet,
	}
}
