import {createStore, compose, applyMiddleware} from 'redux'
import {responsiveStoreEnhancer} from 'redux-responsive'

import thunkMiddleware from 'redux-thunk'

// import the root reducer
import rootReducer from './reducers/index'

// create an object for the default data
const defaultState = {}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(
	applyMiddleware(thunkMiddleware),
	responsiveStoreEnhancer
)
const store = createStore(rootReducer, defaultState, enhancer)

if (module.hot) {
	module.hot.accept('./reducers/', () => {
		const nextRootReducer = require('./reducers/index').default
		store.replaceReducer(nextRootReducer)
	})
}

export default store
