function progress(state = {
	saveStatus: 'ready'
}, action) {

	switch(action.type) {
		case 'requestSaveForm':
		case 'receiveSubmitForm':
		case 'receiveSaveForm':
		case 'requestTestUpdate':
		case 'receiveTestUpdate': {
			return Object.assign({}, state, {
				saveStatus: action.saveStatus,
				progressMessage: action.progressMessage,
				percent: action.percent,
				barColor: action.barColor,
				showProgressBar: action.showProgressBar
			})
		}
		case 'dismissProgressBar':
			return Object.assign({}, state, {
				showProgressBar: action.showProgressBar
			})
		case 'errorReceiveTestUpdate':{
			return Object.assign({}, state, {
				progressMessage: action.progressMessage,
				barColor: action.barColor,
			})
		}
		default:
			return state
	}
}

export default progress
