import crossroads from './SessionActions'

export function requestPublicDetails() {
	return {
		type: 'requestPublicDetails',
	}
}
export function fetchPublicDetails() {
	return function(dispatch) {
		dispatch(requestPublicDetails())

		return crossroads
			.get('/me?publicDetails&profiles')
			.then(json => dispatch(receivePublicDetails(json)))
			.catch(err => dispatch(receivePublicDetails(err)))
	}
}
export function receivePublicDetails(json) {
	if (json.errors === true || json.errors === 'yes' || !json.publicDetails)
		return {
			type: 'errorReceivePublicDetails',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receivePublicDetails',
		publicDetails: json.publicDetails,
		profiles: json.profiles,
	}
}

export function requestGoogleAccessToken() {
	return {
		type: 'requestGoogleAccessToken',
	}
}
export function fetchGoogleAccessToken() {
	return function(dispatch) {
		dispatch(requestGoogleAccessToken())

		return crossroads
			.get('/user/googleAccessToken')
			.then(json => dispatch(receiveGoogleAccessToken(json)))
			.catch(err => dispatch(receiveGoogleAccessToken(err)))
	}
}
export function receiveGoogleAccessToken(json) {
	if (
		json.errors === true ||
		json.errors === 'yes' ||
		!json.googleAccessToken
	)
		return {
			type: 'errorReceiveGoogleAccessToken',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveGoogleAccessToken',
		googleAccessToken: json.googleAccessToken,
	}
}

export function requestUser(email) {
	return {
		type: 'requestUser',
		email,
	}
}
export function fetchUser(email) {
	return function(dispatch) {
		dispatch(requestUser(email))

		return crossroads
			.get('/user/publicDetails', {params: {email}})
			.then(json => dispatch(receiveUser(json)))
			.catch(err => dispatch(receiveUser(err)))
	}
}
export function receiveUser(json) {
	if (json.errors === true || json.errors === 'yes' || !json.publicDetails)
		return {
			type: 'errorUser',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveUser',
		publicDetails: json.publicDetails,
	}
}

export function requestUsersBulk(emails) {
	return {
		type: 'requestUsersBulk',
		emails,
	}
}
export function fetchUsersBulk(params) {
	return function(dispatch) {
		dispatch(requestUsersBulk(params.emails))

		return crossroads
			.get('/user/bulk', {params})
			.then(json => dispatch(receiveUsersBulk(json)))
			.catch(err => dispatch(receiveUsersBulk(err)))
	}
}
export function receiveUsersBulk(json) {
	if (json.errors === true || json.errors === 'yes' || !json.users)
		return {
			type: 'errorUser',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveUsersBulk',
		users: json.users,
	}
}

export function requestSubcircles() {
	return {
		type: 'requestSubcircles',
	}
}
export function fetchSubcircles(params) {
	return function(dispatch) {
		dispatch(requestSubcircles())

		return crossroads
			.get('/circles/subcircles', {params})
			.then(json => dispatch(receiveSubcircles(json)))
			.catch(err => dispatch(receiveSubcircles(err)))
	}
}
export function receiveSubcircles(json) {
	if (json.errors === true || json.errors === 'yes' || !json.subcircles)
		return {
			type: 'errorSubcircles',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveSubcircles',
		subcircles: json.subcircles,
		rootCircle: json.rootCircle,
	}
}

export function requestCircleMembers() {
	return {
		type: 'requestCircleMembers',
	}
}
export function fetchCircleMembers(params) {
	return function(dispatch) {
		dispatch(requestCircleMembers())

		return crossroads
			.get('/circles/members', {params})
			.then(json => dispatch(receiveCircleMembers(json)))
			.catch(err => dispatch(receiveCircleMembers(err)))
	}
}
export function receiveCircleMembers(json) {
	if (json.errors === true || json.errors === 'yes' || !json.members)
		return {
			type: 'errorReceiveCircleMembers',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveCircleMembers',
		members: json.members,
	}
}

export function requestMySubcircles() {
	return {
		type: 'requestMySubcircles',
	}
}
export function fetchMySubcircles(params) {
	return function(dispatch) {
		dispatch(requestMySubcircles())

		return crossroads
			.get('/circles/subcircles', {params})
			.then(json => dispatch(receiveMySubcircles(json)))
			.catch(err => dispatch(receiveMySubcircles(err)))
	}
}
export function receiveMySubcircles(json) {
	if (json.errors === true || json.errors === 'yes' || !json.subcircles)
		return {
			type: 'errorReceiveMySubcircles',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveMySubcircles',
		subcircles: json.subcircles,
		rootCircle: json.rootCircle,
	}
}

export function requestSubcircleMembers(id) {
	return {
		type: 'requestSubcircleMembers',
		id,
	}
}
export function fetchSubcircleMembers(params) {
	return function(dispatch) {
		dispatch(requestSubcircleMembers(params.id))

		return crossroads
			.get('/circles/members', {params})
			.then(json => dispatch(receiveSubcircleMembers(json)))
			.catch(err => dispatch(receiveSubcircleMembers(err)))
	}
}
export function receiveSubcircleMembers(json) {
	if (json.errors === true || json.errors === 'yes' || !json.members)
		return {
			type: 'errorReceiveSubcircleMembers',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveSubcircleMembers',
		members: json.members,
		id: json.id,
	}
}

export function requestCreateCircle() {
	return {
		type: 'requestCreateCircle',
	}
}
export function fetchCreateCircle(body) {
	return function(dispatch) {
		dispatch(requestCreateCircle())

		return crossroads
			.post('/circles/subcircles', {body})
			.then(json => dispatch(receiveCreateCircle(json)))
			.catch(err => dispatch(receiveCreateCircle(err)))
	}
}
export function receiveCreateCircle(json) {
	if (json.errors === true || json.errors === 'yes' || !json.circle)
		return {
			type: 'errorReceiveCreateCircle',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveCreateCircle',
		circle: json.circle,
	}
}

export function requestExperienceType() {
	return {
		type: 'requestExperienceType',
	}
}
export function fetchExperienceType(context) {
	return function(dispatch) {
		dispatch(requestExperienceType())

		return crossroads
			.get('/me/experience', {params: {context}})
			.then(json => dispatch(receiveExperienceType(json)))
			.catch(err => dispatch(receiveExperienceType(err)))
	}
}
export function receiveExperienceType(json) {
	if (json.errors === true || json.errors === 'yes' || !json.experience)
		return {
			type: 'errorReceiveExperienceType',
			errors: json.errors,
			errorMessage: json.errorMessage,
		}

	return {
		type: 'receiveExperienceType',
		experience: json.experience,
		location: json.location,
		analysis: json.analysis,
	}
}
