import {createMuiTheme} from '@material-ui/core/styles'
import colors from './data/colors'
import '@fontsource/open-sans'

export const theme = createMuiTheme({
	typography: {
		fontFamily: '"Open Sans"',
	},
	palette: {
		primary: {
			main: colors.primaryBlue,
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: colors.darkBlue,
		},
		error: {
			main: colors.red,
		},
		warning: {
			main: colors.orange,
		},
	},
})
