const blankState = {
	fetchState: ''
}

function conversationsPM(state = {
	fetchState: 'readyToFetch',
	fellows: [],
	conversations: {}
}, action) {

	switch(action.type) {

	case 'requestConversationsByTitle':
		return Object.assign({}, state, {
			fetchState: 'isFetching',
			isFetching: 'conversations'
		})
	case 'receiveConversationsByTitle': {
		return Object.assign({}, state, {
			fetchState: 'fetchComplete',
			conversations: action.conversations,
			fellows: action.fellows,
			activeTitle: action.activeTitle
		})
	}

	case 'requestUpdateSingleAnswersSet': {
		const conversations = state.conversations
		const conversation = conversations[action.fellowEmail]
		conversations[action.fellowEmail] = Object.assign({}, conversation, {
			fetchState: 'isFetching'
		})
		return Object.assign({}, state, {
			conversations
		})
	}

	case 'requestSingleAnswersSet': {
		const conversations = state.conversations
		const conversation = conversations[action.fellowEmail]
		conversations[action.fellowEmail] = Object.assign({}, conversation, {
			fetchState: 'isFetching'
		})
		return Object.assign({}, state, {
			conversations
		})
	}
	case 'receiveSingleAnswersSet': {
		const {answersSet} = action
		const {email, type} = answersSet
		const conversations = state.conversations
		const conversation = conversations[email]
		let key = 'answersSet'
		if(type === 'self')
			key += 'Self'
		if(type === 'manager')
			key += 'Manager'
		conversations[email] = Object.assign({}, conversation, {
			fetchState: 'fetchComplete',
			[key]: answersSet
		})
		return Object.assign({}, state, {
			conversations
		})
	}

	case 'requestSingleScoresDocument': {
		const conversations = state.conversations
		const conversation = conversations[action.fellowEmail]
		conversations[action.fellowEmail] = Object.assign({}, conversation, {
			singleScoresDocumentFetchState: 'isFetching'
		})
		return Object.assign({}, state, {
			conversations
		})
	}

	case 'receiveSingleScoresDocument': {
		const {scalesScoresDocument} = action
		const {email, type} = scalesScoresDocument
		const conversations = state.conversations
		const conversation = conversations[email]
		let key = 'scalesScoresDocument'
		if(type === 'self')
			key += 'Self'
		if(type === 'manager')
			key += 'Manager'
		conversations[email] = Object.assign({}, conversation, {
			singleScoresDocumentFetchState: 'fetchComplete',
			[key]: scalesScoresDocument
		})
		return Object.assign({}, state, {
			conversations
		})
	}

	case 'requestUpdateSingleScoresDocument': {
		const conversations = state.conversations
		const conversation = conversations[action.fellowEmail]
		conversations[action.fellowEmail] = Object.assign({}, conversation, {
			singleScoresDocumentFetchState: 'isFetching'
		})
		return Object.assign({}, state, {
			conversations
		})
	}

	case 'requestLogout':
		return Object.assign({}, blankState)

	default:
		return state

	}

}

export default conversationsPM
