import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {fetchRecordError} from '../actions/actionCreators'
import Error from './Error'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {hasError: false}
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			errorName: error.name,
			errorMessage: error.message,
		}
	}

	componentDidCatch(error, info) {
		const {fetchRecordError} = this.props
		// fetchRecordError(1000, error.message, {
		// 	userAgent: navigator.userAgent,
		// 	componentStack: info.componentStack,
		// })
	}

	render() {
		const {hasError, errorMessage, errorName} = this.state
		if (hasError) {
			return <Error errorName={errorName} errorMessage={errorMessage} />
		}

		return this.props.children
	}
}

ErrorBoundary.propTypes = {
	fetchRecordError: PropTypes.func,
	children: PropTypes.any,
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({fetchRecordError}, dispatch)
}

export default connect(
	null,
	mapDispatchToProps
)(ErrorBoundary)
