const blankState = {
	conversationTitles: [],
	conversations: {},
	fetchState: '',
}
function analysis(
	state = {
		conversations: {},
		conversationTitles: [],
		fetchState: 'readyToFetch',
	},
	action
) {
	switch (action.type) {
		case 'requestReviewerConversationList':
			return {
				...state,
				fetchState: 'isFetching',
			}
		case 'receiveReviewerConversationList':
			return {
				...state,
				conversations: action.conversations,
				conversationTitles: action.conversationTitles,
				fetchState: 'fetchComplete',
			}

		case 'requestLogout':
			return {...blankState}

		default:
			return state
	}
}

export default analysis
