import {combineReducers} from 'redux'
import {responsiveStateReducer} from 'redux-responsive'
import {responsiveDrawer} from 'material-ui-responsive-drawer'

import accessToken from './accessTokenReducer'
import user from './userReducer'
import users from './usersReducer'
import messages from './messagesReducer'
import circle from './circleReducer'
import circles from './circlesReducer'
import conversations from './conversationsReducer'
import conversationsPM from './conversationsPMReducer'
import conversationSettings from './conversationSettingsReducer'
import questionsSets from './questionsSetsReducer'
import analysis from './analysisReducer'
import viewableContent from './viewableContentReducer'
import appbar from './appbarReducer'
import menu from './menuReducer'
import classroom from './classroomReducer'
import progress from './progress'
import search from './searchReducer'
import reviewerList from './reviewerListReducer'

const rootReducer = combineReducers({
	accessToken,
	user,
	users,
	messages,
	circle,
	circles,
	classroom,
	conversations,
	conversationsPM,
	conversationSettings,
	questionsSets,
	appbar,
	menu,
	analysis,
	progress,
	search,
	viewableContent,
	reviewerList,
	browser: responsiveStateReducer,
	responsiveDrawer: responsiveDrawer,
})

export default rootReducer
