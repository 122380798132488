import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {
	Typography,
	Button,
	Card,
	CardContent,
	CardActions,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	TextField,
} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import ReloadIcon from '@material-ui/icons/Replay'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiLink from '@material-ui/core/Link'
import breakpoints from '../data/breakpoints'

const ErrorContainer = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`
const ErrorCardContainer = styled(Card)`
	max-width: 500px;
	padding: 10px;
	@media (max-width: ${breakpoints.sm}px) {
		margin: 10px;
	}
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	@media (max-width: ${breakpoints.sm}px) {
		.MuiCardActions-root {
			display: block;
		}
		.MuiButtonBase-root {
			margin-top: 10px;
		}
	}
`

const Error = ({errorMessage, errorName, userMessage}) => {
	return (
		<ErrorContainer>
			<ErrorCardContainer variant="outlined">
				<CardContent>
					<Typography variant="h5" gutterBottom align="center">
						Something went wrong
					</Typography>
					<Typography variant="h6" gutterBottom align="center">
						{userMessage && userMessage}
					</Typography>
					<ButtonContainer>
						<CardActions>
							<Button
								variant="outlined"
								startIcon={<ReloadIcon />}
								color="primary"
								onClick={() => this.setState({hasError: false})}
							>
								<span>Reload page</span>
							</Button>
							<Button
								component={Link}
								to="/"
								replace={true}
								color="primary"
								variant="contained"
								startIcon={<HomeIcon />}
								onClick={() => this.setState({hasError: false})}
							>
								Return to Home Page
							</Button>
						</CardActions>
					</ButtonContainer>
					<Typography variant="body1">
						Please open a ticket on the{' '}
						<MuiLink
							href="https://teachforindia.keka.com/ui/#/me/helpdesk/mytickets"
							color="primary"
						>
							helpdesk
						</MuiLink>{' '}
						under the topic of Technology - Firststeps, if this
						issue persists.
					</Typography>
					<br />
					<br />
					{errorName ? (
						<Accordion elevation={0}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>Error details</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TextField
									label={errorName}
									value={
										errorMessage && errorMessage.toString()
									}
									InputProps={{
										readOnly: true,
									}}
									error
									fullWidth
									multiline
								/>
							</AccordionDetails>
						</Accordion>
					) : null}
				</CardContent>
			</ErrorCardContainer>
		</ErrorContainer>
	)
}

Error.propTypes = {
	errorName: PropTypes.string,
	errorMessage: PropTypes.string,
	userMessage: PropTypes.string,
}

export default Error
