const blankState = {
	messagesHistory: [],
	currentMessage: null,
}

function messages(
	state = {
		messagesHistory: [],
		currentMessage: null,
	},
	action
) {
	switch (action.type) {
		case 'requestUpdateSingleAnswersSet':
		case 'receiveEditScalesScores':
		case 'requestEditScalesScores':
		case 'receiveSingleAnswersSet':
		case 'receiveSingleScoresDocument':
		case 'requestUpdateSingleScoresDocument':
		case 'errorReceiveAnalyticsRaw':
		case 'receiveBulkCreateConversations': {
			if (state.currentMessage === action.message) {
				return state
			} else {
				const messagesHistory = state.messagesHistory.slice(0)
				messagesHistory.push(action.message)
				return Object.assign({}, state, {
					messagesHistory,
					currentMessage: action.message,
				})
			}
		}
		case 'clearMessage':
			return Object.assign({}, state, {
				currentMessage: null,
			})

		case 'requestLogout':
			return Object.assign({}, blankState)

		default:
			return state
	}
}

export default messages
