const blankState = {
	fetchState: ''
}

function accessToken(state = {
	accessToken: undefined,
	fetchState: 'readyToFetch'
}, action) {
	switch(action.type) {
	case 'requestAccessToken':
		return Object.assign({}, state, {
			fetchState: 'isFetching'
		})

	case 'receiveAccessToken':
		return Object.assign({}, state, {
			accessToken: action.accessToken,
			loginURI: action.loginURI,
			fetchState: 'fetchComplete'
		})

	case 'requestLogout':
		return Object.assign({}, blankState, {
			fetchState: 'isFetching'
		})

	default:
		return state
	}
}

export default accessToken