const blankState = {
	subcircles: [],
	fetchState: ''
}

function circles(state = {
	subcircles: [],
	fetchState: 'readyToFetch'
}, action) {
	switch(action.type) {

	case 'requestSubcircles':
		return Object.assign({}, state, {
			fetchState: 'isFetching'
		})

	case 'receiveSubcircles':
		return Object.assign({}, state, {
			subcircles: action.subcircles,
			id: action.rootCircle.id,
			name: action.rootCircle.name,
			fetchState: 'fetchComplete'
		})

	case 'requestLogout':
		return Object.assign({}, blankState)

	default:
		return state
	}
}

export default circles
