function appbar(
	state = {
		title: 'Firststeps',
		documentTitle: 'Home - Firststeps',
	},
	action
) {
	switch (action.type) {
		case 'setAppbarTitle':
			return Object.assign({}, state, {
				title: action.title,
				documentTitle: action.documentTitle,
			})
		default:
			return state
	}
}

export default appbar
