const blankState = {
	schema: {},
}

function classroom(
	state = {
		schema: {},
		schemaFetchState: 'readyToFetch',
		classrooms: [],
		classCodes: [],
		classroomsFetchState: 'readyToFetch',
	},
	action
) {
	switch (action.type) {
		case 'requestSchema':
			return Object.assign({}, state, {
				schemaFetchState: 'isFetching',
			})
		case 'receiveSchema':
			return Object.assign({}, state, {
				schema: action.fields,
				schemaFetchState: 'fetchComplete',
			})
		case 'requestClassroomData':
			return Object.assign({}, state, {
				classroomsFetchState: 'isFetching',
			})
		case 'receiveClassroomData': {
			const {classCodes} = state
			action.classrooms.forEach(classroom => {
				classCodes.push(classroom.classCode)
			})
			return Object.assign({}, state, {
				classrooms: action.classrooms,
				classroomsFetchState: 'fetchComplete',
				classCodes,
			})
		}
		case 'requestUpdateClassroomData':
		case 'requestSingleClassroomData': {
			const {classCode} = action
			const {classrooms, classCodes} = state
			const activeClassroomIndex = classrooms.findIndex(classroom => classroom.classCode === classCode)
			const activeClassroom = classrooms[activeClassroomIndex]
			if(activeClassroom) {
				activeClassroom.fetchState = 'isFetching'
				classrooms[activeClassroomIndex] = activeClassroom
			}
			else {
				classCodes.push(classCode)
				classrooms.push({classCode})
			}

			return Object.assign({}, state, {
				classrooms,
				classCodes,
			})
		}
		case 'receiveSingleClassroomData': {
			const {classroom} = action
			const {classrooms} = state
			const activeClassroomIndex = classrooms.findIndex(localClassroom => classroom.classCode === localClassroom.classCode)
			const activeClassroom = classrooms[activeClassroomIndex]
			activeClassroom.fetchState = 'fetchComplete'
			classrooms[activeClassroomIndex] = Object.assign({}, activeClassroom, classroom)
			return Object.assign({}, state, {
				classrooms,
			})
		}
		case 'requestLogout':
			return blankState
		default:
			return state
	}
}

export default classroom
