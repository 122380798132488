const blankState = {
	conversationTitles: [],
	conversations: {},
	fetchState: '',
}
function analysis(
	state = {
		conversations: {},
		conversationTitles: [],
		fetchState: 'readyToFetch',
	},
	action
) {
	switch (action.type) {
		case 'requestFellowAnalytics':
			return Object.assign({}, state, {
				fetchState: 'isFetching',
			})
		case 'receiveFellowAnalytics':
			return Object.assign({}, state, {
				conversations: action.conversations,
				conversationTitles: action.conversationTitles,
				fetchState: 'fetchComplete',
			})

		case 'requestDetailFellowAnalytics': {
			const conversations = state.conversations
			const conversation = conversations[action.title]
			conversations[action.title] = Object.assign({}, conversation, {
				fetchState: 'isFetching',
			})
			return Object.assign({}, state, {
				conversations,
			})
		}
		case 'requestDetailFellowAnalyticsCompletion': {
			const conversations = state.conversations
			const conversation = conversations[action.title]
			conversations[action.title] = Object.assign({}, conversation, {
				completionFetchState: 'isFetching',
			})
			return Object.assign({}, state, {
				conversations,
			})
		}
		case 'requestFilteredFellowAnalyticsCompletion': {
			const conversations = state.conversations
			const {title, filters, groups} = action
			const key = JSON.stringify(filters)
				.concat(groups)
				.toString()
			conversations[title] = {
				...conversations[title],
				completion: {
					...conversations[title].completion,
					[key]: {},
				},
				completionFetchState: 'isFetching',
			}
			return Object.assign({}, state, {conversations})
		}
		case 'requestFilteredFellowAnalytics': {
			const conversations = state.conversations
			const {title, filters, groups} = action
			const key = JSON.stringify(filters)
				.concat(groups)
				.toString()
			conversations[title][key] = Object.assign(
				{},
				{fetchState: 'isFetching'}
			)
			return Object.assign({}, state, {conversations})
		}

		case 'receiveFilteredFellowAnalyticsCompletion': {
			const {title} = action.conversation
			const {filters, groups} = action
			const key = JSON.stringify(filters)
				.concat(groups)
				.toString()
			const conversations = Object.assign({}, state.conversations)
			conversations[title] = {
				...conversations[title],
				completion: {
					...conversations[title].completion,
					[key]: action.conversation,
				},
				completionFetchState: 'fetchComplete',
			}
			return Object.assign({}, state, {conversations})
		}
		case 'receiveFilteredFellowAnalytics': {
			const {title} = action.conversation
			const {filters, groups} = action
			const key = JSON.stringify(filters)
				.concat(groups)
				.toString()
			const conversations = Object.assign({}, state.conversations)
			conversations[title][key] = Object.assign({}, action.conversation, {
				fetchState: 'fetchComplete',
			})
			return Object.assign({}, state, {conversations})
		}

		case 'receiveDetailFellowAnalytics': {
			const {title} = action
			const conversation = state.conversations[title] || {}
			const conversations = Object.assign({}, state.conversations)
			conversations[title] = Object.assign({}, conversation, {
				distribution: action.distribution,
				distributionList: action.distributionList,
				fetchState: 'fetchComplete',
			})
			return Object.assign({}, state, {conversations})
		}
		case 'receiveDetailFellowAnalyticsCompletion': {
			const {title} = action.conversation
			const conversation = state.conversations[title] || {}
			const conversations = Object.assign({}, state.conversations)
			conversations[title] = Object.assign(
				{},
				conversation,
				action.conversation,
				{completionFetchState: 'fetchComplete'}
			)
			return Object.assign({}, state, {conversations})
		}

		case 'requestLogout':
			return Object.assign({}, blankState)

		default:
			return state
	}
}

export default analysis
