import crossroads from './SessionActions'

const tfiBlue = '#32bdd8',
	tfiGreen = '#0c9b6a',
	error = '#b00020'

export function requestTestUpdate(saveStatus = 'done') {
	return {
		type: 'requestTestUpdate',
		saveStatus,
		progressMessage: 'Saving Changes',
		percent: 10,
		barColor: tfiBlue,
		showProgressBar: true,
	}
}

export function fetchTestUpdate(body, saveStatus) {
	return function(dispatch) {
		dispatch(requestTestUpdate(saveStatus))

		return crossroads
			.post('/user/quiz', {body})
			.then(json => {
				dispatch(receiveTestUpdate(json))
				setTimeout(function() {
					dispatch(dismissProgressBar())
				}, 1500)
			})
			.catch(err => {
				dispatch(receiveTestUpdate({errors: true, err}))
			})
	}
}
export function receiveTestUpdate(json) {
	if (json.errors) {
		return {
			type: 'errorReceiveTestUpdate',
			progressMessage:
				'Your response could not be saved, please check your internet connection',
			barColor: error,
		}
	} else
		return {
			type: 'receiveTestUpdate',
			saveStatus: 'done',
			progressMessage: 'Changes Saved :)',
			percent: 100,
			barColor: tfiGreen,
			showProgressBar: true,
			state: json.state,
			startTime: json.startTime,
			conversationId: json.conversationId,
		}
}

export function hideSecondaryMenu(hideSecondaryMenu) {
	return {
		type: 'hideSecondaryMenu',
		hideSecondaryMenu,
	}
}

export function fetchTestRemainingTime(quizSet) {
	return function(dispatch) {
		dispatch(hideSecondaryMenu(true))
		return crossroads
			.get('/user/quiz/remainingTime', {params: {quizSet}})
			.then(json => {
				dispatch(receiveTestRemainingTime(json))
			})
	}
}
export function receiveTestRemainingTime(json) {
	return {
		type: 'receiveTestRemainingTime',
		saveStatus: 'done',
		progressMessage: 'Changes Saved :)',
		percent: 100,
		barColor: tfiGreen,
		showProgressBar: true,
		startTime: json.startTime,
		remainingTime: json.remainingTime,
		conversationId: json.conversationId,
		state: json.state,
	}
}

export function dismissProgressBar() {
	return {
		type: 'dismissProgressBar',
		showProgressBar: false,
	}
}
