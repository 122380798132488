function viewableContent(state = {
	viewableContent: 'summary'
}, action) {
	switch(action.type) {

	case 'setActiveConversation':
		return Object.assign({}, state, {
			viewableContent: 'summary'
		})
    case 'setViewableContent':
		return Object.assign({}, state, {
			viewableContent: action.content
		})

	default:
		return state
	}
}

export default viewableContent
