const config = {
	crossroads: {
		apiKey: process.env.API_KEY,
		clientId: process.env.CLIENT_ID,
		host: process.env.CROSSROADS_HOST,
		port: parseInt(process.env.CROSSROADS_PORT),
		shouldAppBeSecure: true,
	},
	applicationHost: process.env.APPLICATION_HOST,
	serverPort: process.env.SERVER_PORT,
	analytics: {
		gaTrackingId: process.env.GA_TRACKING_ID,
	},
	errorLogging: {
		sendToSlack: process.env.SEND_TO_SLACK,
		applicationName: 'Firststeps-Dev',
	},
	google: {
		clientId: process.env.GOOGLE_CLIENT_ID,
		redirectUri: process.env.REDIRECT_URI,
		apiKey: process.env.GOOGLE_API_KEY,
	},
	conversationDate: process.env.CONVERSATION_DATE, //(yyyy/mm/dd)
}
module.exports = config
